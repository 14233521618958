export default (allErrorData: string[], get: string) => {
  if (
    allErrorData?.filter?.(
      (errorData: string) => errorData.split(" ")[0] === get
    )?.length
  )
    return allErrorData
      ?.filter?.((errorData: string) => errorData.split(" ")[0] === get)
      .map((errorData: string) => {
        let result = errorData
          .split(" ")
          .splice(1, errorData.split(" ").length)
          .join(" ");

        return result.charAt(0).toUpperCase() + result.slice(1, result.length);
      });
  return false;
};
