import { FormEvent, useEffect, useState } from "react";
import { AiFillCloseCircle } from "react-icons/ai";

import {
  useCheckInMutation,
  useLazyGetLastHistoryQuery,
} from "../../../../services/history";

import Input from "../../../../components/Input";
import Modal from "../../../../components/Modal";
import Button from "../../../../components/Button";
import Skeleton from "../../../../components/Skeleton";
import Swal from "sweetalert2";

const Component = ({
  id,
  isOpen = false,
  onClose = () => null,
  metadata = {},
}: {
  id: number;
  isOpen: boolean;
  onClose: any;
  metadata: any;
}) => {
  // State
  const [formData, setFormData] = useState<{
    adult: number;
    concession: number;
  }>({
    adult: 1,
    concession: 1,
  });

  // Queries
  const [triggerGetLastHistory, resultLastHistory] =
      useLazyGetLastHistoryQuery(),
    [triggerCheckIn, resultCheckIn] = useCheckInMutation();

  // Functions
  const handleCheckin = (formElement: FormEvent) => {
    formElement.preventDefault();
    triggerCheckIn({ data: formData, UserId: id });
  };

  // Effects
  useEffect(() => {
    if (id) triggerGetLastHistory(id);
  }, [id]);

  useEffect(() => {
    const resultData: any = resultCheckIn.error || resultCheckIn;
    if (
      resultCheckIn.status === "fulfilled" ||
      resultCheckIn.status === "rejected"
    )
      Swal.fire(
        resultCheckIn.isSuccess ? "Success" : "Error",
        resultData.data?.message,
        resultCheckIn.isSuccess ? "success" : "error"
      ).finally(() => {
        if (resultCheckIn.isSuccess) onClose();
      });
  }, [resultCheckIn]);

  useEffect(() => {
    setFormData({
      adult: metadata?.profileCategory?.visitorLimit?.adult || 1,
      concession: metadata?.profileCategory?.visitorLimit?.concession || 1,
    });
  }, [metadata]);

  return (
    <Modal isOpen={isOpen}>
      {resultLastHistory.isSuccess ? (
        <div className="w-full">
          <div className="flex justify-between items-center">
            <h1 className="text-xl">
              {/* {JSON.stringify(resultLastHistory)} */}
              {!resultLastHistory?.data || resultLastHistory?.data?.outAt
                ? "Check-in"
                : "Check-out"}
            </h1>
            <span
              className="text-red-500 cursor-pointer"
              onClick={() => onClose()}
            >
              <AiFillCloseCircle />
            </span>
          </div>
          <form onSubmit={handleCheckin} className="flex flex-col gap-5 mt-5">
            {!resultLastHistory?.data || resultLastHistory?.data?.outAt ? (
              <>
                <p>Please confirm the number of visitors</p>
                <Input
                  name="adult"
                  value={formData.adult}
                  label="Adult"
                  type="number"
                  min={1}
                  max={metadata?.profileCategory?.visitorLimit?.adult || 1}
                  onChange={(e: any) =>
                    setFormData({ ...formData, adult: e.target.value })
                  }
                />
                <Input
                  name="concession"
                  value={formData.concession}
                  label="Concession"
                  type="number"
                  min={1}
                  max={metadata?.profileCategory?.visitorLimit?.concession || 1}
                  onChange={(e: any) =>
                    setFormData({ ...formData, concession: e.target.value })
                  }
                />
                <div className="flex">
                  <Button type="submit" className="bg-teal-700 text-white">
                    Check-in
                  </Button>
                </div>
              </>
            ) : (
              <>
                <h3 className="text-lg">
                  Are you sure you want to check-out this account?
                </h3>
                <div className="flex">
                  <Button type="submit" className="bg-teal-700 text-white">
                    Check-out
                  </Button>
                </div>
              </>
            )}
          </form>
        </div>
      ) : (
        <Skeleton />
      )}
    </Modal>
  );
};

export default Component;
