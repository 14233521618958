import { ReactNode } from "react";
import { AiFillWarning, AiOutlineWarning } from "react-icons/ai";

export enum Variant {
  failure = "failure",
  success = "success",
  warning = "warning",
}

const Component = ({
  show,
  message,
  variant = Variant.success,
}: {
  show: boolean;
  message: ReactNode | string;
  variant?: Variant;
}) => {
  const variantClasses = () => {
    switch (variant) {
      case Variant.failure:
        return {
          root: "my-5 bg-red-300 dark:bg-red-700 dark:text-white",
          icon: <AiOutlineWarning />,
          text: "text-md",
        };
      case Variant.success:
        return {
          root: "my-5 bg-green-300 dark:bg-green-700 dark:text-white",
          icon: <AiOutlineWarning />,
          text: "text-md",
        };
      case Variant.warning:
        return {
          root: "my-5 bg-yellow-300 dark:bg-yellow-700 dark:text-white",
          icon: <AiOutlineWarning />,
          text: "text-md",
        };
    }
  };

  console.log({ variant });

  return show ? (
    <div
      className={`flex rounded-lg p-3 items-center ${variantClasses().root}`}
    >
      <div className="text-lg">{variantClasses().icon}</div>
      <span className={`ml-3 ${variantClasses().text}`}>
        <span>{message}</span>
      </span>
    </div>
  ) : null;
};

export default Component;
