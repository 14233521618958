import { ReactNode } from "react";
import { Transition } from "@headlessui/react";

const Component = ({
  children = <></>,
  isOpen = false,
}: {
  children: ReactNode;
  isOpen: boolean;
}) => {
  // States
  //   const [isOpen, setIsOpen] = useState(true);

  return (
    <Transition
      show={isOpen}
      enter="transition-opacity duration-150"
      enterFrom="opacity-0"
      enterTo="opacity-100"
      leave="transition-opacity duration-150"
      leaveFrom="opacity-100"
      leaveTo="opacity-0"
    >
      <div
        aria-hidden="true"
        className={`flex bg-gray-800/50 overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 w-full md:inset-0 h-modal md:h-full justify-center items-center`}
      >
        <div className="relative p-4 w-full max-w-2xl h-full md:h-auto">
          <div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
            <div className="flex justify-between items-start p-4 rounded-t border-b dark:border-gray-600">
              {children}
            </div>
          </div>
        </div>
      </div>
    </Transition>
  );
};

export default Component;
