import { SyntheticEvent, useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { useDispatch } from "react-redux";

// Layout
import Layout from "./Layout";

// Components
import Input from "../../components/Input";
import Button from "../../components/Button";
import Alert, { Variant as AlertVariant } from "../../components/Alert";
import Spinner from "../../components/Spinner";
import { useLoginMutation, userApi } from "../../services/user";
import { setCredentials } from "../../store/userSlice";

// Services

const Page = () => {
  // Hooks
  const dispatch = useDispatch(),
    navigate = useNavigate();

  // RTK Query
  const [triggerLoginMutation, resultLoginMutation] = useLoginMutation();

  // State
  const [identityType, setIdentityType] = useState("text"),
    [loginState, setLoginState] = useState({
      identity: "",
      password: "",
    }),
    [error, setError] = useState<boolean | string>(false);

  // Functions
  const triggerLogin = (event: SyntheticEvent) => {
    let data = loginState;
    data.identity = data.identity?.split?.(" ")?.join("");
    event.preventDefault();
    setError(false);
    triggerLoginMutation(data)
      .unwrap()
      .then((result: any) => {
        dispatch(
          setCredentials({
            access_token: result.access_token || "",
          })
        );

        navigate("/", {
          replace: true,
        });
      })
      .catch((error: any) => {
        console.error(error);
        setError(
          `Oops, ${
            error.data?.message ||
            "there was an error trying to login. Please try again"
          }`
        );
      });
  };

  return (
    <Layout>
      <div className="h-[calc(100vh-44px-2.5rem)] overflow-auto pr-10">
        <div className="h-full flex items-center">
          <div className="w-full">
            <h1 className="flex items-center text-3xl font-bold dark:text-white">
              Login
            </h1>
            <Alert
              variant={AlertVariant.failure}
              show={!!error}
              message={error}
            />
            <form onSubmit={triggerLogin}>
              <div className="py-5">
                <div>
                  <Input
                    label="Email Address / Username / Phone Number"
                    type={identityType}
                    name="identity"
                    onBlur={(e: any) => {
                      let sgValue = e.target.value,
                        phoneNumberSgValue = Number(
                          sgValue?.replace("+", "").split(" ").join("")
                        );
                      if (sgValue.charAt(0) == "0")
                        sgValue = sgValue.replace("0", "+65");

                      if (
                        !isNaN(phoneNumberSgValue) &&
                        sgValue.length &&
                        sgValue.charAt(0) !== "+"
                      )
                        sgValue = `+${sgValue}`;
                      if (
                        e.target.value?.length > 2 &&
                        !isNaN(phoneNumberSgValue)
                      ) {
                        setIdentityType("tel");
                        setLoginState({
                          ...loginState,
                          identity: sgValue,
                        });
                      } else {
                        setIdentityType("text");
                        setLoginState({
                          ...loginState,
                          identity: sgValue,
                        });
                      }
                    }}
                    onChange={(e: any) => {
                      if (!e.target.value?.length && identityType === "tel")
                        setIdentityType("text");
                      setLoginState({
                        ...loginState,
                        identity: e.target.value,
                      });
                    }}
                    value={loginState.identity}
                    placeholder={
                      identityType === "tel"
                        ? "Phone number"
                        : "Email / Username / Phone"
                    }
                  />
                </div>
                <div className="pt-5">
                  <Input
                    label="Password"
                    type="password"
                    name="password"
                    onChange={(e: any) => {
                      setLoginState({
                        ...loginState,
                        password: e.target.value,
                      });
                    }}
                    value={loginState.password}
                    placeholder="Password"
                  />
                </div>
              </div>
              <div className="flex justify-end mt-5 mb-5">
                <Button
                  className={`text-white bg-gradient-to-br from-green-400 to-blue-600 hover:bg-gradient-to-bl focus:ring-4 focus:outline-none focus:ring-green-200 dark:focus:ring-green-800 font-medium rounded-lg text-sm px-5 py-2.5 text-center ${
                    resultLoginMutation.isLoading
                      ? "cursor-not-allowed"
                      : "cursor-pointer"
                  }`}
                  type={resultLoginMutation.isLoading ? "button" : "submit"}
                  disabled={resultLoginMutation.isLoading}
                >
                  {resultLoginMutation.isLoading ? <Spinner /> : "Login"}
                </Button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Page;
