const Component = (props: any) => {
  const children: any = props.children,
    className: string = props.className,
    onClick: any = props.onClick,
    type: any = props.type || "button",
    disabled: boolean = props.disabled || false;

  return (
    <button
      className={`px-4 py-2 rounded shadow hover:shadow-md outline-none mr-1 mb-1 ${className}`}
      type={type}
      onClick={onClick}
      disabled={disabled}
    >
      {children}
    </button>
  );
};
export default Component;
