import { ReactNode, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { userApi } from "../../services/user";
import { setCredentials, setUser } from "../../store/userSlice";
export enum ProtectFromType {
  UNLOGGED = "UNLOGGED",
  LOGGED = "LOGGED",
}
export enum RoleType {
  ADMIN = "ADMIN",
  USER = "USER",
  ANY = "ANY",
}
const ProtectedRoute = ({
  children,
  protectFrom,
  forRole,
}: {
  children: ReactNode;
  protectFrom?: ProtectFromType | undefined;
  forRole?: RoleType;
}) => {
  // Hooks
  const dispatch = useDispatch(),
    navigate = useNavigate();

  const userData = useSelector((state: any) => state.user);
  const [triggerGetOwnProfile, resultOwnProfile] =
    userApi.endpoints.getOwnProfile.useLazyQuery();

  useEffect(() => {
    if (localStorage.getItem("token"))
      dispatch(setCredentials({ access_token: localStorage.getItem("token") }));

    switch (protectFrom) {
      case ProtectFromType.LOGGED:
        if (userData.token || localStorage.getItem("token")) {
          return navigate("/", { replace: true });
        }
        break;
      case ProtectFromType.UNLOGGED:
        if (!(userData.token || localStorage.getItem("token")))
          return navigate("/", { replace: true });

        if (!userData.user) triggerGetOwnProfile("");
        break;
    }
  }, [children]);

  useEffect(() => {
    if (userData.user && protectFrom === ProtectFromType.UNLOGGED) {
      switch (forRole) {
        case RoleType.ADMIN:
          if (userData.user.role !== "ADMIN") navigate("/", { replace: true });
          break;
        case RoleType.USER:
          if (userData.user?.role !== "USER") navigate("/", { replace: true });
          break;
        default:
          break;
      }
    }
  }, [userData]);

  useEffect(() => {
    if (!resultOwnProfile.isLoading && !resultOwnProfile.isUninitialized) {
      if (resultOwnProfile.isSuccess) dispatch(setUser(resultOwnProfile.data));
    }
  }, [resultOwnProfile]);

  return <>{children}</>;
};

export default ProtectedRoute;
