import { ReactNode } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useLocation } from "react-router";
import { setCredentials } from "../../store/userSlice";

const Component = (props: any) => {
  const navigate = useNavigate(),
    location = useLocation(),
    dispatch = useDispatch();

  const icon: ReactNode = props.icon,
    name: any = props.children,
    isActive: boolean = props.isActive,
    pathname: string = props.pathname,
    isLogout: boolean = props.isLogout,
    className: string | null = props.className;

  const handleLogout = () => {
    dispatch(setCredentials({ access_token: null }));
    return navigate("/");
  };

  return (
    <div
      className={`p-3 md:text-white md:flex block items-center text-xs md:text-lg ${
        isActive ? "bg-teal-600 dark:bg-teal-800 text-white" : ""
      } hover:bg-teal-600 hover:dark:bg-teal-800 hover:text-white cursor-pointer transition-colors duration-200 rounded-lg ${className}`}
      onClick={() =>
        isLogout
          ? handleLogout()
          : location.pathname !== pathname
          ? navigate(pathname)
          : null
      }
    >
      <div className="text-2xl md:text-lg flex md:block justify-center">
        {icon}
      </div>
      <p className="md:ml-3 text-center md:text-start">{name}</p>
    </div>
  );
};

export default Component;
