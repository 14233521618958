import { SyntheticEvent, useEffect, useState } from "react";
import { useDispatch } from "react-redux";

// Layout
import Layout from "./Layout";

// Components
import Input from "../../components/Input";
import Spinner from "../../components/Spinner";
import Button from "../../components/Button";
import Toast, { ToastType } from "../../components/Toast";

// Services
import { useGetProfileTypesQuery } from "../../services/profileType";
import { profileCategoryApi } from "../../services/profileCategory";
import { useCreateAccountMutation } from "../../services/user";
import errorMutationGetters from "../../helpers/errorMutationGetters";
import setAccessToken from "../../helpers/auth/setAccessToken";
import { useNavigate } from "react-router-dom";
import { setCredentials } from "../../store/userSlice";

const Page = () => {
  // Hooks
  const dispatch = useDispatch(),
    navigate = useNavigate();

  const profileTypes = useGetProfileTypesQuery("");
  const [triggerProfileCategorySlug, resultProfileCategorySlug] =
    profileCategoryApi.endpoints.getProfileCategories.useLazyQuery();

  const formDataDefault = {
      profileCategory: "",
      profileType: "",
    },
    [formData, setFormData] = useState<any>(formDataDefault);

  const [createAccountTrigger, resultCreateAccount] =
      useCreateAccountMutation(),
    [messageResultCreateAccount, setMessageResultCreateAccount] =
      useState<any>(false);

  const submitRegistration = (event: SyntheticEvent) => {
    event.preventDefault();

    setMessageResultCreateAccount(false);
    createAccountTrigger({
      accountType:
        (Array.isArray(profileTypes?.data) ? profileTypes?.data : [])
          ?.filter?.(
            (profileType: any) =>
              profileType.slug?.toLowerCase?.() ===
              formData.profileType.toLowerCase()
          )?.[0]
          ?.name?.toLowerCase?.() || "",
      body: formData,
    });
  };

  useEffect(
    () =>
      setFormData({
        ...formDataDefault,
        profileType: formData.profileType,
      }),
    [resultProfileCategorySlug]
  );
  useEffect(() => {
    if (formData.profileType) triggerProfileCategorySlug(formData.profileType);
  }, [formData.profileType]);

  useEffect(() => {
    if (formData.profileCategory) {
      let data: {
        [key: string]: any;
      } = {
        ...formDataDefault,
        profileCategory: formData.profileCategory,
        profileType: formData.profileType,
      };
      data.data = {};
      (Array.isArray(profileTypes.data) ? profileTypes.data : [])
        ?.filter?.(
          (profileType: any) => profileType.slug === formData.profileType
        )?.[0]
        ?.fields?.map((el: any) => {
          data.data[el.name] = "";
        });

      setFormData(data);
    }
  }, [formData.profileCategory]);

  useEffect(() => {
    let result: any = resultCreateAccount;
    if (!!result.isSuccess) {
      dispatch(setCredentials({ access_token: result.data.access_token }));
      navigate("/", {
        replace: true,
        state: {
          from: result.data.from,
        },
      });
    }

    if ("data" in (result.error || {})) {
      if (Array.isArray(result.error?.data?.message))
        return setMessageResultCreateAccount(
          !!resultCreateAccount.error ? result.error?.data?.message : []
        );
      else setMessageResultCreateAccount(result.error?.data?.message);
    }
  }, [resultCreateAccount]);

  return (
    <Layout>
      <div className="h-[calc(100vh-44px-2.5rem)] overflow-auto pr-10">
        <div className="h-full flex m-auto">
          <div className="w-full px-2 m-auto">
            <h1 className="flex items-center text-3xl font-bold dark:text-white mb-5">
              New Registration
            </h1>
            <form onSubmit={submitRegistration}>
              {profileTypes.isLoading ? (
                <div className="mt-5">
                  <Spinner />
                </div>
              ) : (
                <div className="mb-5">
                  <Input
                    type="select"
                    value={formData.profileType}
                    defaultPlaceholder="Profile type"
                    options={
                      Array.isArray(profileTypes.data)
                        ? profileTypes.data?.map?.((el: any) => ({
                            label: el.name,
                            value: el.slug,
                          }))
                        : null
                    }
                    onChange={(e: any) =>
                      setFormData({
                        ...formData,
                        profileType: e.target.value,
                      })
                    }
                  />
                </div>
              )}

              {resultProfileCategorySlug.isLoading ? (
                <div className="mt-5">
                  <Spinner />
                </div>
              ) : !resultProfileCategorySlug.isUninitialized ? (
                <div className="mb-5">
                  <Input
                    childId={`category-input`}
                    type="select"
                    value={formData.profileCategory}
                    defaultPlaceholder="Profile category"
                    options={
                      Array.isArray(resultProfileCategorySlug.data)
                        ? resultProfileCategorySlug.data?.map?.((el: any) => ({
                            label: el.name,
                            value: el.slug,
                          }))
                        : []
                    }
                    onChange={(e: any) => {
                      setFormData({
                        ...formData,
                        profileCategory: e.target.value,
                      });
                    }}
                  />
                </div>
              ) : null}

              {formData.profileCategory ? (
                <>
                  <div className="dark:text-white">
                    <div className="mb-5">
                      <Input
                        label="Full Name"
                        type="text"
                        error={errorMutationGetters(
                          messageResultCreateAccount,
                          "fullname"
                        )}
                        required={true}
                        value={formData.fullname}
                        onChange={(e: any) =>
                          setFormData({
                            ...formData,
                            fullname: e.target.value,
                          })
                        }
                      />
                    </div>

                    <div className="mb-5">
                      <Input
                        label="Email address"
                        type="email"
                        error={errorMutationGetters(
                          messageResultCreateAccount,
                          "email"
                        )}
                        required={true}
                        value={formData.email}
                        onChange={(e: any) =>
                          setFormData({
                            ...formData,
                            email: e.target.value,
                          })
                        }
                      />
                    </div>

                    <div className="mb-5">
                      <Input
                        label="Phone number"
                        type="tel"
                        error={errorMutationGetters(
                          messageResultCreateAccount,
                          "phoneNumber"
                        )}
                        required={true}
                        value={formData.phoneNumber}
                        onChange={(e: any) =>
                          setFormData({
                            ...formData,
                            phoneNumber: e.target.value,
                          })
                        }
                      />
                    </div>

                    <div className="mb-5">
                      <Input
                        label="Password"
                        type="password"
                        error={errorMutationGetters(
                          messageResultCreateAccount,
                          "password"
                        )}
                        required={true}
                        value={formData.password}
                        onChange={(e: any) =>
                          setFormData({
                            ...formData,
                            password: e.target.value,
                          })
                        }
                      />
                    </div>
                    {(Array.isArray(profileTypes.data) ? profileTypes.data : [])
                      ?.filter?.(
                        (profileType: any) =>
                          profileType.slug === formData.profileType
                      )?.[0]
                      ?.fields?.map((el: any, i: number) => (
                        <div className="mb-5" key={`${el.name}-${i}`}>
                          <Input
                            childId={`${el.name}-${i}-input`}
                            type={el.type}
                            hideLabel={false}
                            label={el.placeholder}
                            placeholder={el.placeholder}
                            defaultPlaceholder={el.placeholder}
                            options={el.options}
                            required={el.required}
                            value={formData.data?.[el.name]}
                            error={errorMutationGetters(
                              messageResultCreateAccount,
                              `data.${el.name}`
                            )}
                            onChange={(e: any) =>
                              setFormData({
                                ...formData,
                                data: {
                                  ...formData.data,
                                  [el.name]: e.target.value,
                                },
                              })
                            }
                          />
                        </div>
                      ))}
                  </div>
                  <Button
                    type="submit"
                    className={`bg-teal-500 dark:bg-teal-700 text-white rounded-lg mb-20 ${
                      resultCreateAccount.status === "pending"
                        ? "cursor-not-allowed"
                        : "cursor-pointer"
                    }`}
                    disabled={resultCreateAccount.status === "pending"}
                  >
                    {resultCreateAccount.status === "pending" ? (
                      <div className="flex justify-center items-center">
                        <Spinner />
                      </div>
                    ) : (
                      "Submit"
                    )}
                  </Button>
                </>
              ) : null}
            </form>
          </div>
        </div>
      </div>
      <Toast
        timeout={5}
        type={ToastType.ALERT}
        show={typeof messageResultCreateAccount === "string"}
        // show={true}
        onShowToast={(state: boolean) => setMessageResultCreateAccount(state)}
        message={messageResultCreateAccount}
      />
    </Layout>
  );
};

export default Page;
