import Layout from "./Layout";
import Table from "../../components/Table";
import { useLazyGetHistoryQuery } from "../../services/history";
import { useEffect, useState } from "react";
import moment from "moment";
const Component = () => {
  const [triggerGetHistory, resultGetHistory] = useLazyGetHistoryQuery();

  const [paginationSetup, setPaginationSetup] = useState({
    page: 1,
    limit: 10,
  });
  useEffect(() => {
    triggerGetHistory(paginationSetup);
  }, []);
  // Hooks
  return (
    <Layout title={"History"}>
      {resultGetHistory.data?.data?.map?.((el: any, i: number) => (
        <div className="px-5 py-5 bg-gray-300 mb-3" key={`history-${i}`}>
          {/* {JSON.stringify(el)} */}
          <table className="border-separate border-spacing-x-4">
            <tbody>
              <tr>
                <td>Check-in at</td>
                <td>
                  {moment(el.createdAt).isValid()
                    ? moment(el.createdAt).format("HH:mm DD MMMM YYYY")
                    : "-"}
                </td>
              </tr>
              <tr>
                <td>Check-out at</td>
                <td>
                  {moment(el.outAt).isValid()
                    ? moment(el.outAt).format("HH:mm DD MMMM YYYY")
                    : "-"}
                </td>
              </tr>

              <tr>
                <td>Adult</td>
                <td>{el.data.adult || "-"}</td>
              </tr>
              <tr>
                <td>Concession</td>
                <td>{el.data.concession || "-"}</td>
              </tr>
            </tbody>
          </table>
        </div>
      ))}
    </Layout>
  );
};
export default Component;
