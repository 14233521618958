// import { Button } from "flowbite-react";
// import { useNavigate } from "react-router-dom";
import { useEffect, useLayoutEffect, useRef, useState } from "react";
import Layout from "./Layout";

import QRCode from "../../components/QRScanner";

const Page = () => {
  const bodyRef = useRef(null);
  // State
  const [size, setSize] = useState({
      width: 0,
      height: 0,
    }),
    [canCheckIn, setCanCheckIn] = useState(false);

  useLayoutEffect(() => {
    const refResult: any = bodyRef?.current;
    setSize({
      width: refResult?.clientWidth,
      height: refResult?.clientHeight,
    });
  }, []);

  useEffect(() => {
    if (size.width > 768) setCanCheckIn(false);
    else setCanCheckIn(true);
  }, [size]);
  // Hooks
  return (
    <Layout title={"Scan QR"} ref={bodyRef}>
      <div className="w-full h-full relative">
        {canCheckIn ? (
          <QRCode />
        ) : (
          <>
            <h1 className="text-2xl font-bold">Please use mobile browser</h1>
            <p>To get the maximum experience </p>
          </>
        )}
      </div>
    </Layout>
  );
};
export default Page;
