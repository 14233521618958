import { ReactNode, useEffect, useState } from "react";

import { BiLogInCircle } from "react-icons/bi";
import { BsPencil, BsFillTrashFill } from "react-icons/bs";

import Layout from "../Layout";
import Table from "../../../components/Table";
import Skeleton from "../../../components/Skeleton";
import Pagination from "../../../components/Pagination";
import Button from "../../../components/Button";
import Input from "../../../components/Input";

import CheckIn from "./Members/CheckIn";
import Edit from "./Members/Edit";
import Delete from "./Members/Delete";

import { useLazyGetAllProfilesQuery } from "../../../services/user";
import { useLazyGetProfileTypesQuery } from "../../../services/profileType";
import { FaSearch } from "react-icons/fa";

// Interfaces
export interface UserType {
  Name: string;
  "Phone number": string;
  "Profile category": string;
  "Profile type": string;
  Actions: ReactNode;
}

export enum UserActionType {
  "DELETE" = "DELETE",
  "UPDATE" = "UPDATE",
  "CHECK_IN" = "CHECK_IN",
}

export interface UserAction {
  type: UserActionType;
  id: number;
  metadata?: any;
}

const Page = () => {
  // Query
  const [triggerGetUsers, resultGetUsers] = useLazyGetAllProfilesQuery(),
    [triggerGetProfileTypes, resultGetProfileTypes] =
      useLazyGetProfileTypesQuery();

  // State
  const // [pagination, setPagination] = useState({
    //     page: 1,
    //     limit: 8,
    //   }),
    [userData, setUserData] = useState<UserType[]>([]),
    [queryFetchUser, setQueryFetchUser] = useState({
      page: 1,
      limit: 8,
      fullname: "",
      profileTypeId: "",
    }),
    [searchUser, setSearchUser] = useState(""),
    // [profileType, setProfileType] = useState(""),
    [userAction, setUserAction] = useState<UserAction | null>(null),
    [profileTypeList, setProfileTypeList] = useState<any>([]);

  // Functions
  const fetchUser = () => triggerGetUsers(queryFetchUser);

  // Effects
  useEffect(() => {
    if (resultGetUsers.isSuccess)
      setUserData(
        resultGetUsers.data?.data?.map?.((user: any) => ({
          Name: `${user?.firstName} ${user?.lastName}`,
          "Phone number": `${user?.phoneNumber}`,
          "Profile category": user?.profileCategory?.name,
          "Profile type": user?.profileType?.name,
          Actions: (
            <div className="flex gap-2">
              <Button
                className="text-lg text-green-600"
                onClick={() =>
                  setUserAction({
                    id: user.id,
                    type: UserActionType.CHECK_IN,
                    metadata: user,
                  })
                }
              >
                <BiLogInCircle />
              </Button>
              <Button
                className="text-lg text-yellow-600"
                onClick={() =>
                  setUserAction({
                    id: user.id,
                    type: UserActionType.UPDATE,
                    metadata: user,
                  })
                }
              >
                <BsPencil />
              </Button>
              <Button
                className="text-lg text-red-600"
                onClick={() =>
                  setUserAction({
                    id: user.id,
                    type: UserActionType.DELETE,
                    metadata: user,
                  })
                }
              >
                <BsFillTrashFill />
              </Button>
            </div>
          ),
        }))
      );
  }, [resultGetUsers]);

  useEffect(() => {
    if (resultGetProfileTypes.isSuccess && !resultGetUsers.isLoading)
      fetchUser();
  }, [resultGetProfileTypes, queryFetchUser]);

  useEffect(() => {
    if (resultGetProfileTypes.isSuccess) {
      const resultData: any = resultGetProfileTypes.data;
      setProfileTypeList(
        resultData?.map?.((el: any) => ({ label: el.name, value: el.id }))
      );
    }
  }, [resultGetProfileTypes]);

  useEffect(() => {
    triggerGetProfileTypes("");
  }, []);

  return (
    <Layout title="Members">
      {!resultGetUsers.isSuccess ? (
        <Skeleton />
      ) : (
        <>
          <div className="flex justify-between relative mb-5 my-3">
            <div className="flex">
              <Input
                type="select"
                options={profileTypeList}
                defaultPlaceholder="All"
                value={queryFetchUser.profileTypeId}
                onChange={(e: any) => {
                  setSearchUser("");
                  setQueryFetchUser({
                    ...queryFetchUser,
                    profileTypeId: e.target.value,
                    page: 1,
                    fullname: "",
                  });
                  // setProfileType(e.target.value);
                  // fetchUser(true);
                }}
                enableDefaultOption={true}
              />
            </div>
            <div className="flex">
              <div className="flex gap-5">
                <Input
                  type="text"
                  label="Search by name"
                  value={searchUser}
                  onChange={(e: any) => {
                    setSearchUser(e.target.value);
                  }}
                />
                <Button
                  type="button"
                  onClick={() =>
                    setQueryFetchUser({
                      ...queryFetchUser,
                      fullname: searchUser,
                      page: 1,
                    })
                  }
                >
                  <FaSearch />
                </Button>
              </div>
            </div>
          </div>
          {resultGetUsers.data?.data?.length ? (
            <>
              <Table data={userData} />
              <div className="my-5">
                <Pagination
                  currentPage={queryFetchUser.page}
                  limitData={queryFetchUser.limit}
                  totalData={resultGetUsers?.data?.pagination?.total}
                  onPageChange={(page: number) =>
                    setQueryFetchUser({ ...queryFetchUser, page })
                  }
                />
              </div>
            </>
          ) : (
            <h1 className="text-lg font-bold">No user found at this time</h1>
          )}
        </>
      )}
      <CheckIn
        id={userAction?.id || 0}
        isOpen={userAction?.type === UserActionType.CHECK_IN}
        onClose={(state: boolean) => (!state ? setUserAction(null) : null)}
        metadata={userAction}
      />
      <Edit
        id={userAction?.id || 0}
        isOpen={userAction?.type === UserActionType.UPDATE}
        onClose={(state: boolean) => (!state ? setUserAction(null) : null)}
        metadata={userAction?.metadata}
        triggerRefreshListProfile={fetchUser}
      />
      <Delete
        id={userAction?.id || 0}
        isOpen={userAction?.type === UserActionType.DELETE}
        onClose={(state: boolean) => (!state ? setUserAction(null) : null)}
        triggerRefreshListProfile={fetchUser}
      />
    </Layout>
  );
};

export default Page;
