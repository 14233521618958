import { ReactNode } from "react";
import { createBrowserRouter } from "react-router-dom";
import ProtectedRoute, {
  ProtectFromType,
  RoleType,
} from "../helpers/auth/ProtectedRoute";

// Middlewares
import RootChecker from "../helpers/router/RootChecker";
// import isLoggedIn from "../helpers/router/isLoggedin";

// Elements
// Wrapper
// Auth
import Login from "./Auth/Login";
import Register from "./Auth/Register";

// Universal
import Settings from "./Dashboard/Settings";

// Dashboard
import Dashboard from "./Dashboard";
import DashboardEdit from "./Dashboard/Edit";
import DashboardHistory from "./Dashboard/History";
import DashboardQRCode from "./Dashboard/QRScanner";
// Admin Dashboard
import AdminDashboard from "./Dashboard/Admin";
import AdminDashboardHistory from "./Dashboard/Admin/History";
import AdminDashboardMembers from "./Dashboard/Admin/User";

import Root from "./Root";

const root = [
  { path: "/", element: <RootChecker /> },
  {
    path: "login",
    element: (
      <ProtectedRoute protectFrom={ProtectFromType.LOGGED}>
        <Login />
      </ProtectedRoute>
    ),
  },
  {
    path: "register",
    element: (
      <ProtectedRoute protectFrom={ProtectFromType.LOGGED}>
        <Register />
      </ProtectedRoute>
    ),
  },
];

const customerDashboard = [
  {
    path: "/customer/dashboard",
    element: <Dashboard />,
  },
  {
    path: "/customer/dashboard/edit",
    element: <DashboardEdit />,
  },

  {
    path: "/customer/dashboard/qrcode",
    element: <DashboardQRCode />,
  },
  {
    path: "/customer/dashboard/history",
    element: <DashboardHistory />,
  },
].map(({ path, element }: { path: string; element: ReactNode }) => ({
  path,
  element: (
    <ProtectedRoute
      protectFrom={ProtectFromType.UNLOGGED}
      forRole={RoleType.USER}
    >
      {element}
    </ProtectedRoute>
  ),
}));

const adminDashboard = [
  {
    path: "/admin/dashboard",
    element: <AdminDashboard />,
  },
  {
    path: "/admin/dashboard/history",
    element: <AdminDashboardHistory />,
  },
  {
    path: "/admin/dashboard/members",
    element: <AdminDashboardMembers />,
  },
  {
    path: "/admin/dashboard/edit",
    element: <DashboardEdit />,
  },
].map(({ path, element }: { path: string; element: ReactNode }) => ({
  path,
  element: (
    <ProtectedRoute
      protectFrom={ProtectFromType.UNLOGGED}
      forRole={RoleType.ADMIN}
    >
      {element}
    </ProtectedRoute>
  ),
}));

export default createBrowserRouter([
  {
    path: "/",
    element: <Root />,
    children: root,
  },
  {
    path: "/customer/dashboard",
    element: <Root />,
    children: customerDashboard,
  },

  {
    path: "/admin/dashboard",
    element: <Root />,
    children: adminDashboard,
  },

  {
    path: "/settings",
    element: (
      <ProtectedRoute
        protectFrom={ProtectFromType.UNLOGGED}
        forRole={RoleType.ANY}
      >
        <Settings />
      </ProtectedRoute>
    ),
  },
]);
