// Dependencies
import { useLocation } from "react-router";
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { MdDashboard, MdHistory, MdLogout } from "react-icons/md";
import { BsPeopleFill } from "react-icons/bs";

// Components
import PageButton from "./PageButtons";
import { FaQrcode } from "react-icons/fa";

// Data interfaces
import { PageList as PageListType } from "./index.d";

const Components = (props: any) => {
  // Init Hooks
  const location = useLocation();

  // Selector
  const userData = useSelector((state: any) => state.user);

  // State
  const [pageList, setPageList] = useState<PageListType[]>([
      {
        id: `${-new Date()}-` + 1 + "-top",
        name: "Dashboard",
        icon: <MdDashboard />,
        pathname: "/customer/dashboard",
      },

      {
        id: `${-new Date()}-` + 2 + "-top",
        name: "Scan QR",
        icon: <FaQrcode />,
        pathname: "/customer/dashboard/qrcode",
        hideOnDesktop: true,
      },
      {
        id: `${-new Date()}-` + 3 + "-top",
        name: "History",
        icon: <MdHistory />,
        pathname: "/customer/dashboard/history",
      },
    ]),
    [pageListBottom] = useState<PageListType[]>([
      {
        id: `${-new Date()}-` + 1 + "-btm",
        name: "Logout",
        icon: <MdLogout />,
        pathname: "/login",
        isLogout: true,
        hideOnMobile: true,
      },
    ]);

  // Props Extractor
  const className: string = props.className;

  // Side Effects
  useEffect(() => {
    if (userData.user) {
      if (userData.user.role === "ADMIN")
        setPageList([
          {
            id: `${-new Date()}-` + 1 + "-top",
            name: "Dashboard",
            icon: <MdDashboard />,
            pathname: "/admin/dashboard",
          },
          {
            id: `${-new Date()}-` + 2 + "-top",
            name: "History",
            icon: <MdHistory />,
            pathname: "/admin/dashboard/history",
          },
          {
            id: `${-new Date()}-` + 3 + "-top",
            name: "Members",
            icon: <BsPeopleFill />,
            pathname: "/admin/dashboard/members",
          },
        ]);
      else
        setPageList([
          {
            id: `${-new Date()}-` + 1 + "-top",
            name: "Dashboard",
            icon: <MdDashboard />,
            pathname: "/customer/dashboard",
          },

          {
            id: `${-new Date()}-` + 2 + "-top",
            name: "Scan QR",
            icon: <FaQrcode />,
            pathname: "/customer/dashboard/qrcode",
            hideOnDesktop: true,
          },
          {
            id: `${-new Date()}-` + 3 + "-top",
            name: "History",
            icon: <MdHistory />,
            pathname: "/customer/dashboard/history",
          },
        ]);
    }
  }, [userData]);
  return (
    <aside
      className={`p-2 w-full bottom-0 md:relative md:p-5 border-t md:border-0 md:bg-teal-500 md:dark:bg-teal-700 ${className}`}
    >
      <div className="hidden md:block ">
        {/* Logo */}
        <h1 className="text-3xl text-white font-bold mb-5">LKCNHM</h1>

        <hr />
      </div>
      <div className="md:mt-5 flex bg-inherit md:static md:flex-col md:h-[calc(100vh-5rem-37px)]">
        <div className="flex md:block justify-between flex-1 md:flex-none">
          {pageList?.map?.((el: any) => (
            <PageButton
              className={
                el.hideOnMobile
                  ? "hidden md:flex"
                  : el.hideOnDesktop
                  ? "md:hidden"
                  : ""
              }
              key={el?.id}
              icon={el.icon}
              isActive={el.pathname === location.pathname}
              pathname={el.pathname}
            >
              {el.name}
            </PageButton>
          ))}
        </div>
        <div className="mt-auto hidden md:block">
          {pageListBottom?.map?.((el: any) => (
            <PageButton
              className={el.hideOnMobile ? "hidden md:flex" : ""}
              key={el?.id}
              icon={el.icon}
              isActive={el.pathname === location.pathname}
              pathname={el.pathname}
              isLogout={el.isLogout}
            >
              {el.name}
            </PageButton>
          ))}
        </div>
      </div>
    </aside>
  );
};

export default Components;
