import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";

type AuthState = {
  user: any | null;
  token: string | null;
};

const slice = createSlice({
  name: "user",
  initialState: { user: null, token: null } as AuthState,
  reducers: {
    setCredentials: (
      state,
      {
        payload: { access_token },
      }: PayloadAction<{ access_token: string | null }>
    ) => {
      if (access_token) {
        localStorage.setItem(
          "token",
          `Bearer ${access_token?.replace?.("Bearer ", "")}`
        );
        state.token = `Bearer ${access_token?.replace?.("Bearer ", "")}`;
      } else {
        localStorage.removeItem("token");
        state.token = null;
        state.user = null;
      }
    },
    setUser: (state, { payload }: any) => {
      state.user = payload;
    },
  },
});

export const { setCredentials, setUser } = slice.actions;

export default slice.reducer;
