import ReactPaginate from "react-paginate";

const Component = ({
  currentPage = 1,
  limitData = 10,
  totalData = 1000,
  limitPage = 5,
  onPageChange = () => null,
}: {
  currentPage?: number;
  limitData?: number;
  totalData?: number;
  limitPage?: number;
  onPageChange?: any;
}) => {
  return (
    <div className="mt-5">
      <ReactPaginate
        className="flex"
        breakLabel={<p className="py-2 px-3">...</p>}
        pageClassName="py-2 px-3 leading-tight text-gray-500 bg-white hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
        pageLinkClassName="leading-tight text-gray-500 border-gray-300 dark:text-gray-400 dark:hover:text-white"
        activeClassName="leading-tight text-blue-600 bg-blue-50 border-blue-300 hover:bg-blue-100 hover:text-blue-700 dark:border-gray-700 dark:bg-gray-700 dark:text-white"
        activeLinkClassName="leading-tight text-blue-600 bg-blue-50 hover:bg-blue-100 hover:text-blue-700 dark:border-gray-700 dark:bg-gray-700 dark:text-white"
        nextLabel={
          <p className="py-2 px-3">
            <span className="sr-only">Next</span>
            <svg
              aria-hidden="true"
              className="w-5 h-5"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                clipRule="evenodd"
              ></path>
            </svg>
          </p>
        }
        initialPage={currentPage - 1}
        onPageChange={(a) => onPageChange(a.selected + 1)}
        pageRangeDisplayed={limitPage}
        marginPagesDisplayed={1}
        pageCount={Math.ceil(totalData / limitData)}
        previousLabel={
          <p className="py-2 px-3">
            <span className="sr-only">Previous</span>
            <svg
              aria-hidden="true"
              className="w-5 h-5"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z"
                clipRule="evenodd"
              ></path>
            </svg>
          </p>
        }
        renderOnZeroPageCount={() => null}
      />
    </div>
  );
};

export default Component;
