import { useState, useEffect, ReactNode, useRef } from "react";
import { AiFillWarning, AiOutlineClose, AiOutlineCheck } from "react-icons/ai";
import { CSSTransition } from "react-transition-group";
export enum ToastType {
  SUCCESS = "SUCCESS",
  ERROR = "ERROR",
  ALERT = "ALERT",
}
const Component = ({
  timeout = 5,
  message = "",
  type = ToastType.SUCCESS,
  show = false,
  onShowToast = () => {},
}: {
  timeout?: number;
  message?: ReactNode | string;
  type?: ToastType;
  show: boolean;
  onShowToast: any;
}) => {
  const toastRef = useRef(null);

  useEffect(() => {
    if (show) setTimeout(() => onShowToast(false), timeout * 1000);
  }, [show]);

  const IconComponent = () => {
    switch (type) {
      case ToastType.SUCCESS:
        return (
          <div className="text-green-500 bg-green-100 rounded-lg dark:bg-green-700 dark:text-green-200 text-xl w-8 h-8 p-2 flex justify-center items-center">
            <AiOutlineCheck />
          </div>
        );
      case ToastType.ERROR:
        return (
          <div className="text-red-500 bg-red-100 rounded-lg dark:bg-red-700 dark:text-red-200 text-xl w-8 h-8 p-2 flex justify-center items-center">
            <AiOutlineClose />
          </div>
        );
      case ToastType.ALERT:
        return (
          <div className="text-orange-500 bg-orange-100 rounded-lg dark:bg-orange-700 dark:text-orange-200 text-xl w-8 h-8 p-2 flex justify-center items-center">
            <AiFillWarning />
          </div>
        );
    }
  };

  return (
    <CSSTransition
      in={show}
      nodeRef={toastRef}
      timeout={timeout * 1000}
      classNames="toast"
      unmountOnExit
      // onEnter={() => setShowToast(true)}
      onExited={() => onShowToast(false)}
    >
      <div className="fixed bottom-0 right-0 mr-5 mb-5" ref={toastRef}>
        <div className="flex items-center p-4 w-full max-w-xs text-gray-500 bg-white rounded-lg shadow dark:text-gray-400 dark:bg-gray-700">
          <div className="inline-flex flex-shrink-0 justify-center items-center">
            <IconComponent />
          </div>
          <div className="mx-5 text-sm font-normal">{message}</div>
          <button
            type="button"
            onClick={() => onShowToast(false)}
            className="ml-auto -mx-1.5 -my-1.5 bg-white text-gray-400 hover:text-gray-900 rounded-lg focus:ring-2 focus:ring-gray-300 p-1.5 hover:bg-gray-100 h-8 w-8 dark:text-gray-500 dark:hover:text-white dark:bg-gray-800 dark:hover:bg-gray-700 flex justify-center items-center"
          >
            <AiOutlineClose />
          </button>
        </div>
      </div>
    </CSSTransition>
  );
};

export default Component;
