import { Button } from "flowbite-react";
import { useNavigate } from "react-router-dom";
import Layout from "./Layout";
const Component = () => {
  // Hooks
  const navigate = useNavigate();
  return (
    <Layout>
      Lorem ipsum dolor sit, amet consectetur adipisicing elit. Perspiciatis
      deleniti, praesentium esse officiis illo dignissimos sed nulla blanditiis
      molestias sequi repudiandae nesciunt fugit voluptatum nemo veritatis quae
      pariatur quia sunt!
      <Button onClick={() => navigate("/")} color="dark">
        Edit
      </Button>
    </Layout>
  );
};
export default Component;
