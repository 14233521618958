import { Menu, Transition } from "@headlessui/react";
import { ReactNode, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { setCredentials } from "../../store/userSlice";

enum Position {
  TOP = "TOP",
  BOTTOM = "BOTTOM",
}

interface MenuType {
  next: Function | string;
  showOnMobile: boolean;
  text: string;
  position: Position;
}

const Component = ({
  children,
  title,
}: {
  children: ReactNode;
  title?: string | ReactNode;
}) => {
  // Hooks
  const navigate = useNavigate(),
    dispatch = useDispatch();

  // Selector
  const userState = useSelector((state: any) => state.user.user);

  // State
  const [menu] = useState<MenuType[]>([
    {
      next: "/settings",
      showOnMobile: true,
      text: "Account Settings",
      position: Position.TOP,
    },
    {
      next: () => {
        dispatch(setCredentials({ access_token: null }));
        navigate("/");
      },
      showOnMobile: true,
      text: "Sign Out",
      position: Position.BOTTOM,
    },
  ]);
  return (
    <main className="h-full flex-1 flex flex-col">
      <div className="flex justify-between relative items-center p-5">
        <h1 className="text-2xl">{title}</h1>
        <div className="relative inline-block text-left z-30">
          <Menu>
            {({ open }) => (
              <>
                <span className="rounded-md shadow-sm">
                  <Menu.Button className="inline-flex justify-center w-full px-4 py-2 text-sm font-medium leading-5 text-gray-700 transition duration-150 ease-in-out bg-white border border-gray-300 rounded-md hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue active:bg-gray-50 active:text-gray-800">
                    <span>Hi, {userState?.fullname}</span>
                    <svg
                      className="w-5 h-5 ml-2 -mr-1"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                    >
                      <path
                        fillRule="evenodd"
                        d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                        clipRule="evenodd"
                      />
                    </svg>
                  </Menu.Button>
                </span>

                <Transition
                  show={open}
                  enter="transition ease-out duration-100"
                  enterFrom="transform opacity-0 scale-95"
                  enterTo="transform opacity-100 scale-100"
                  leave="transition ease-in duration-75"
                  leaveFrom="transform opacity-100 scale-100"
                  leaveTo="transform opacity-0 scale-95"
                >
                  <Menu.Items
                    static
                    className="absolute right-0 w-56 mt-2 origin-top-right bg-white border border-gray-200 divide-y divide-gray-100 rounded-md shadow-lg outline-none text-gray-900 z-30"
                  >
                    <div className="px-4 py-3">
                      <p className="text-sm leading-5">Signed in as</p>
                      <p className="text-sm font-medium leading-5 truncate">
                        {userState?.username || userState?.email}
                      </p>
                    </div>

                    <div className="py-1">
                      {menu
                        .filter(
                          (elMenu: MenuType) => elMenu.position === Position.TOP
                        )
                        .map((elMenu: MenuType, idx: number) => (
                          <Menu.Item key={`menu-${idx}-top`}>
                            {({ active }) => (
                              <a
                                onClick={() =>
                                  typeof elMenu.next !== "string"
                                    ? elMenu.next?.()
                                    : navigate(elMenu.next)
                                }
                                className={`${
                                  active
                                    ? "bg-gray-100 text-gray-900"
                                    : "text-gray-700"
                                } flex justify-between w-full px-4 py-2 text-sm leading-5 text-left cursor-pointer`}
                              >
                                {elMenu.text}
                              </a>
                            )}
                          </Menu.Item>
                        ))}
                    </div>

                    <div className="py-1">
                      {menu
                        .filter(
                          (elMenu: MenuType) =>
                            elMenu.position === Position.BOTTOM
                        )
                        .map((elMenu: MenuType, idx: number) => (
                          <Menu.Item key={`menu-${idx}-bottom`}>
                            {({ active }) => (
                              <a
                                onClick={() =>
                                  typeof elMenu.next !== "string"
                                    ? elMenu.next?.()
                                    : navigate(elMenu.next)
                                }
                                className={`${
                                  active
                                    ? "bg-gray-100 text-gray-900"
                                    : "text-gray-700"
                                } flex justify-between w-full px-4 py-2 text-sm leading-5 text-left cursor-pointer`}
                              >
                                {elMenu.text}
                              </a>
                            )}
                          </Menu.Item>
                        ))}
                    </div>
                  </Menu.Items>
                </Transition>
              </>
            )}
          </Menu>
        </div>
      </div>
      <div className="flex-1 overflow-y-auto px-5">{children}</div>
    </main>
  );
};

export default Component;
