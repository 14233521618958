// import { QrReader } from "react-qr-reader";
import { SyntheticEvent, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
// @ts-ignore
import QrScanner from "react-web-qr-reader";
import { useSelector } from "react-redux";
import { historyApi, useCheckInMutation } from "../../services/history";

import Spinner from "../Spinner";
import Input from "../Input";
import Button from "../Button";
import {
  AiFillCheckCircle,
  AiFillCloseCircle,
  AiOutlineFieldTime,
} from "react-icons/ai";

enum StateQR {
  Error = "Error",
  QRScanner = "QRScanner",
  CheckInPrompt = "CheckInPrompt",
  CheckInStatus = "CheckInStatus",
}

interface FormCheckIn {
  adult: number;
  concession: number;
}

const Component = () => {
  const navigate = useNavigate();

  const userState = useSelector((state: any) => state.user.user);

  const [getValidityUUID, resultValidityUUID] =
      historyApi.endpoints.getValidityUUIDOfQR.useLazyQuery(),
    [checkInQuery, resultCheckIn] = useCheckInMutation();

  const [errors, setErrors] = useState<any>(false),
    [checkInResult, setCheckInResult] = useState<any>(false),
    [currentState, setCurrentState] = useState<StateQR>(StateQR.QRScanner),
    [formCheckIn, setFormCheckIn] = useState<FormCheckIn>({
      adult: 0,
      concession: 0,
    }),
    [QRUUID, setQRUUID] = useState<any>(false);

  const handleCheckIn = (event: SyntheticEvent) => {
      event.preventDefault();
      checkInQuery({ uuid: QRUUID, data: formCheckIn });
      setCurrentState(StateQR.CheckInStatus);
    },
    handleCancelCheckIn = () => {
      setFormCheckIn({
        adult: userState?.profileCategory?.visitorLimit?.adult || 0,
        concession: userState?.profileCategory?.visitorLimit?.concession || 0,
      });
      setCurrentState(StateQR.QRScanner);
    };

  useEffect(() => {
    setFormCheckIn({
      adult: userState?.profileCategory?.visitorLimit?.adult,
      concession: userState?.profileCategory?.visitorLimit?.concession,
    });
  }, [userState]);

  useEffect(() => {
    if (
      resultValidityUUID.isSuccess &&
      resultValidityUUID.data &&
      !resultValidityUUID.isFetching
    ) {
      setQRUUID(resultValidityUUID.originalArgs);
      const resultData: any = resultValidityUUID.data;
      if (resultData?.message === "Check-out success") {
        setCheckInResult(resultValidityUUID);
        return setCurrentState(StateQR.CheckInStatus);
      }
      return setCurrentState(StateQR.CheckInPrompt);
    }
  }, [resultValidityUUID]);

  useEffect(() => {
    if (resultCheckIn.isSuccess) {
      setCheckInResult(resultCheckIn);
    }
  }, [resultCheckIn]);
  return (
    <>
      {currentState === StateQR.Error ? (
        <>
          <h1 className="text-2xl font-bold">
            Oops, {errors === "Permission denied" ? "camera error" : ""}
          </h1>
          <p>
            {errors === "Permission denied"
              ? "Please allow LKCNHM Apps to use camera or use another browser"
              : ""}
          </p>
        </>
      ) : currentState === StateQR.QRScanner ? (
        <QrScanner
          delay={500}
          onError={(error) => {
            setErrors(error.message);
            setCurrentState(StateQR.Error);
          }}
          onScan={(scan: any) =>
            !resultValidityUUID.isLoading ? getValidityUUID(scan.data) : null
          }
        />
      ) : currentState === StateQR.CheckInPrompt ? (
        <div className="pt-5">
          <h1 className="text-xl font-bold mb-2">Confirm check-in</h1>
          <p>Please confirm the number of visitors</p>
          <form className="mt-5 flex flex-col gap-4" onSubmit={handleCheckIn}>
            <Input
              type="number"
              label="Adult"
              name="adult"
              min={1}
              max={userState?.profileCategory?.visitorLimit?.adult}
              onChange={(e: any) => {
                setFormCheckIn({
                  ...formCheckIn,
                  adult: e.target.value,
                });
              }}
              value={formCheckIn.adult}
              placeholder="Adult"
            />
            <Input
              type="number"
              label="Concession"
              name="concession"
              min={1}
              max={userState?.profileCategory?.visitorLimit?.concession}
              onChange={(e: any) => {
                setFormCheckIn({
                  ...formCheckIn,
                  concession: e.target.value,
                });
              }}
              value={formCheckIn.concession}
              placeholder="Adult"
            />
            <div className="mt-10 flex justify-between gap-5">
              <Button
                className="flex-1 bg-gray-400 dark:bg-gray-600 text-white"
                type="button"
                onClick={handleCancelCheckIn}
              >
                Cancel
              </Button>
              <Button
                className="flex-1 bg-teal-400 dark:bg-teal-600 text-white"
                type="submit"
              >
                Check-in
              </Button>
            </div>
          </form>
        </div>
      ) : currentState === StateQR.CheckInStatus ? (
        <div className="w-full h-full flex flex-col">
          <div
            className={`place-self-center ${
              checkInResult.isSuccess
                ? "text-green-600 dark:text-green-400"
                : checkInResult.isFetching
                ? "text-blue-600 dark:text-blue-400"
                : "text-red-600 dark:text-red-400"
            } flex flex-col items-center gap-5 pt-5`}
          >
            <div className="icon text-6xl">
              {checkInResult.isSuccess ? (
                <AiFillCheckCircle />
              ) : checkInResult.isFetching ? (
                <AiOutlineFieldTime />
              ) : (
                <AiFillCloseCircle />
              )}
            </div>
            <div className="message text-xl font-bold">
              <h1>
                {checkInResult?.data?.message ||
                  checkInResult?.error?.data?.message ||
                  "Please wait"}
              </h1>
            </div>
          </div>
          <div className="bg-gray-300 dark:bg-gray-700 my-5 px-5 py-3 text-black dark:text-white">
            <table className="border-spacing-x-3 border-separate">
              <tbody>
                {checkInResult.data?.inAt ? (
                  <tr>
                    <td className="whitespace-nowrap">Checked in at</td>
                    <td>:</td>
                    <td>{checkInResult.data.inAt}</td>
                  </tr>
                ) : null}

                {checkInResult.data?.outAt ? (
                  <tr>
                    <td className="whitespace-nowrap">Checked out at</td>
                    <td>:</td>
                    <td>{checkInResult.data.outAt}</td>
                  </tr>
                ) : null}
              </tbody>
            </table>
          </div>
          <div className="mt-5">
            <Button
              className="bg-teal-600 dark:bg-teal-400 text-white"
              onClick={() => {
                setCurrentState(StateQR.QRScanner);
                navigate("/");
              }}
            >
              OK!
            </Button>
          </div>
        </div>
      ) : null}
    </>
  );
};

export default Component;
