const Component = ({
  data,
  name = `${+new Date()}`,
}: {
  data: any[];
  name?: string;
}) => {
  const tableHeads: string[] = Object.keys(data?.[0] || {});
  return (
    <div className="overflow-x-auto relative shadow-md sm:rounded-lg">
      <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
        <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
          <tr>
            {tableHeads.map((tableHead: string, idx: number) => (
              <th
                scope="col"
                className="py-3 px-6"
                key={`${name}-heads-${idx}`}
              >
                {tableHead}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {data.map((el: any, i: number) => (
            <tr
              className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600"
              key={`${name}-dataTable-${i}`}
            >
              {tableHeads.map((tableHead: string, idx: number) => (
                <td
                  className="py-4 px-6"
                  key={`${name}-data-${tableHead}-${idx}`}
                >
                  {el?.[tableHead]}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default Component;
