import { configureStore } from "@reduxjs/toolkit";
// ...

import userSlice from "./userSlice";

import { userApi } from "./../services/user";
import { profileTypeApi } from "../services/profileType";
import { profileCategoryApi } from "../services/profileCategory";
import { historyApi } from "../services/history";

export const store = configureStore({
  reducer: {
    user: userSlice,
    [userApi.reducerPath]: userApi.reducer,
    [historyApi.reducerPath]: historyApi.reducer,
    [profileTypeApi.reducerPath]: profileTypeApi.reducer,
    [profileCategoryApi.reducerPath]: profileCategoryApi.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat([
      userApi.middleware,
      profileTypeApi.middleware,
      profileCategoryApi.middleware,
    ]),
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
