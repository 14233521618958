import { FormEvent, useEffect } from "react";
import { AiFillCloseCircle } from "react-icons/ai";

import Modal from "../../../../components/Modal";
import Button from "../../../../components/Button";
import Swal from "sweetalert2";
import { useDeleteProfileMutation } from "../../../../services/user";

const Component = ({
  isOpen = false,
  onClose = () => null,
  id = 0,
  triggerRefreshListProfile = () => null,
}: {
  isOpen: boolean;
  onClose: any;
  id: number;
  triggerRefreshListProfile: any;
}) => {
  // States

  // Queries
  const [triggerDeleteProfile, resultDeleteProfile] =
    useDeleteProfileMutation();

  // Functions
  const handleDeleteUser = (event: FormEvent) => {
    event.preventDefault();
    triggerDeleteProfile(id);
  };

  useEffect(() => {
    if (resultDeleteProfile.isSuccess)
      Swal.fire("Success", "Success delete profile", "success")
        .then(() => onClose())
        .finally(() => {
          triggerRefreshListProfile();
        });
    else if (resultDeleteProfile.isError) {
      const errorResult: any = resultDeleteProfile.error;
      Swal.fire(
        "Error",
        `Error while delete profile.\n${errorResult?.data?.message} `,
        "error"
      ).finally(() => {
        triggerRefreshListProfile();
      });
    }
  }, [resultDeleteProfile]);

  return (
    <Modal isOpen={isOpen}>
      <div className="w-full">
        <div className="flex justify-between items-center">
          <h1 className="text-xl">Delete user</h1>
          <span
            className="text-red-500 cursor-pointer"
            onClick={() => onClose()}
          >
            <AiFillCloseCircle />
          </span>
        </div>
        <form onSubmit={handleDeleteUser} className="flex flex-col gap-5 mt-5">
          <p>Are you sure you want to delete this user?</p>
          <div className="flex">
            <Button type="submit" className="bg-teal-700 text-white">
              Delete
            </Button>
          </div>
        </form>
      </div>
    </Modal>
  );
};

export default Component;
