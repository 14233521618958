import { Outlet } from "react-router-dom";

const Root = () => {
  return (
    <div className="bg-gray-100 dark:bg-slate-800 transition-all dark:text-white">
      <Outlet />
    </div>
  );
};
export default Root;
