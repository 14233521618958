import { FormEvent, useEffect, useState } from "react";
import { AiFillCloseCircle } from "react-icons/ai";

import Input from "../../../../components/Input";
import Modal from "../../../../components/Modal";
import Button from "../../../../components/Button";
import Skeleton from "../../../../components/Skeleton";
import Swal from "sweetalert2";
import { useUpdateProfileMutation } from "../../../../services/user";

const Component = ({
  isOpen = false,
  metadata = {},
  onClose = () => null,
  id = 0,
  triggerRefreshListProfile = () => null,
}: {
  isOpen: boolean;
  metadata: any;
  onClose: any;
  id: number;
  triggerRefreshListProfile: any;
}) => {
  // States
  const [formData, setFormData] = useState<any>({});

  // Queries
  const [triggerUpdateProfile, resultUpdateProfile] =
    useUpdateProfileMutation();

  // Functions
  const handleEditUser = (event: FormEvent) => {
    event.preventDefault();
    triggerUpdateProfile({ body: formData, id });
  };

  // Effects
  useEffect(() => {
    if (metadata && Object.keys(metadata).length) {
      let data: any = {};

      metadata?.profileType?.fields?.map((el: any) => {
        data[el.name] = metadata?.data?.[el.name];
      });
      setFormData({
        fullname: `${metadata?.firstName}${
          metadata.lastName ? ` ${metadata.lastName}` : ""
        }`,
        email: metadata.email,
        phoneNumber: metadata.phoneNumber,
        data,
      });
    }
  }, [metadata]);

  useEffect(() => {
    if (resultUpdateProfile.isSuccess)
      Swal.fire("Success", "Success updating profile", "success")
        .then(() => onClose())
        .finally(() => {
          triggerRefreshListProfile();
        });
    else if (resultUpdateProfile.isError) {
      const errorResult: any = resultUpdateProfile.error;
      Swal.fire(
        "Error",
        `Error while updating profile.\n${errorResult?.data?.message} `,
        "error"
      ).finally(() => {
        triggerRefreshListProfile();
      });
    }
  }, [resultUpdateProfile]);

  return (
    <Modal isOpen={isOpen}>
      <div className="w-full">
        <div className="flex justify-between items-center">
          <h1 className="text-xl">Edit user</h1>
          <span
            className="text-red-500 cursor-pointer"
            onClick={() => onClose()}
          >
            <AiFillCloseCircle />
          </span>
        </div>
        <form onSubmit={handleEditUser} className="flex flex-col gap-5 mt-5">
          <Input
            type="text"
            label="Fullname"
            value={formData.fullname}
            onChange={(e: any) =>
              setFormData({ ...formData, fullname: e.target.value })
            }
          />
          <Input
            type="email"
            label="Email address"
            value={formData.email}
            onChange={(e: any) =>
              setFormData({ ...formData, email: e.target.value })
            }
          />
          <Input
            type="tel"
            label="Phone number"
            value={formData.phoneNumber}
            hideLabel={true}
            onChange={(e: any) =>
              setFormData({ ...formData, phoneNumber: e.target.value })
            }
          />
          {metadata?.profileType?.fields?.map((field: any) => (
            <Input
              type={field?.type}
              label={field?.placeholder}
              value={formData?.data?.[field?.name]}
              options={field?.options}
              isFlex
              onChange={(e: any) =>
                setFormData({
                  ...formData,
                  data: { ...formData?.data, [field.name]: e.target.value },
                })
              }
            />
          ))}
          <div className="flex">
            <Button type="submit" className="bg-teal-700 text-white">
              Update
            </Button>
          </div>
        </form>
      </div>
    </Modal>
  );
};

export default Component;
