import Layout from "../Layout";
import Table from "../../../components/Table";
import { SyntheticEvent, useEffect, useState } from "react";
import { useLazyGetHistoryQuery } from "../../../services/history";
import Skeleton from "../../../components/Skeleton";
import moment from "moment";
import Pagination from "../../../components/Pagination";
import { Menu, Transition } from "@headlessui/react";
import Input from "../../../components/Input";
import Button from "../../../components/Button";
import { FaSearch } from "react-icons/fa";

// Interfaces
export interface HistoryType {
  Name: string;
  "Check-in time": string;
  "Check-out time": string;
  Adult: number;
  Concesion: number;
}

export enum FilterHistory {
  "DAY" = "Today",
  "WEEK" = "This week",
  "MONTH" = "This month",
  ALL = "All",
}

const Page = () => {
  // Query
  const [triggerGetHistory, resultGetHistory] = useLazyGetHistoryQuery();

  // State
  const [pagination, setPagination] = useState({
      page: 1,
      limit: 10,
    }),
    [historyData, setHistoryData] = useState<HistoryType[]>([]),
    [filterHistory, setFilterHistory] = useState<FilterHistory>(
      FilterHistory.DAY
    );

  const fetchHistory = () =>
    triggerGetHistory({
      ...pagination,
      filter: filterHistory,
    });

  useEffect(() => {
    fetchHistory();
  }, [pagination]);

  useEffect(() => {
    fetchHistory();
  }, [filterHistory]);

  useEffect(() => {
    if (resultGetHistory.isSuccess)
      setHistoryData(
        resultGetHistory.data?.data?.map?.((history: any) => ({
          Name: `${history?.User?.firstName} ${history?.User?.lastName}`,
          "Check-in time": moment(history.createdAt).isValid()
            ? moment(history.createdAt).format("HH:mm DD MMMM YYYY")
            : "-",
          "Check-out time": moment(history.outAt).isValid()
            ? moment(history.outAt).format("HH:mm DD MMMM YYYY")
            : "-",
          Adult: history?.data?.adult,
          Concession: history?.data?.concession,
        }))
      );
  }, [resultGetHistory]);

  return (
    <Layout title="Check-in History">
      {!resultGetHistory.isSuccess ? (
        <Skeleton />
      ) : (
        <>
          <div className="flex justify-between relative mb-5">
            <div className="z-30 py-3">
              <Menu>
                {({ open }) => (
                  <div className="z-30">
                    <span className="rounded-md shadow-sm">
                      <Menu.Button className="inline-flex justify-center w-full px-4 py-2 text-sm font-medium leading-5 text-gray-700 transition duration-150 ease-in-out bg-white border border-gray-300 rounded-md hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue active:bg-gray-50 active:text-gray-800">
                        <span>{filterHistory}</span>
                        <svg
                          className="w-5 h-5 ml-2 -mr-1"
                          viewBox="0 0 20 20"
                          fill="currentColor"
                        >
                          <path
                            fillRule="evenodd"
                            d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                            clipRule="evenodd"
                          />
                        </svg>
                      </Menu.Button>
                    </span>

                    <Transition
                      show={open}
                      enter="transition ease-out duration-100"
                      enterFrom="transform opacity-0 scale-95"
                      enterTo="transform opacity-100 scale-100"
                      leave="transition ease-in duration-75"
                      leaveFrom="transform opacity-100 scale-100"
                      leaveTo="transform opacity-0 scale-95"
                    >
                      <Menu.Items className="absolute left-0 w-56 mt-2 origin-top-right bg-white border border-gray-200 divide-y divide-gray-100 rounded-md shadow-lg outline-none text-gray-900 z-30">
                        {[
                          FilterHistory.DAY,
                          FilterHistory.WEEK,
                          FilterHistory.MONTH,
                          FilterHistory.ALL,
                        ].map((element: FilterHistory, i: number) => (
                          <Menu.Item key={`filter-history-${i}`}>
                            <a
                              onClick={() => setFilterHistory(element)}
                              className={`${
                                element === filterHistory
                                  ? "bg-gray-100 text-gray-900"
                                  : "text-gray-700"
                              } flex justify-between w-full px-4 py-2 text-sm leading-5 text-left cursor-pointer`}
                            >
                              {element}
                            </a>
                          </Menu.Item>
                        ))}
                      </Menu.Items>
                    </Transition>
                  </div>
                )}
              </Menu>
            </div>

            <div className="my-3 flex">{/* export button */}</div>
          </div>
          {resultGetHistory.data?.data?.length ? (
            <>
              <Table data={historyData} />
              <Pagination
                currentPage={pagination.page}
                limitData={pagination.limit}
                totalData={resultGetHistory?.data?.pagination?.total}
                onPageChange={(page: number) =>
                  setPagination({ ...pagination, page })
                }
              />
            </>
          ) : (
            <h1 className="text-lg font-bold">
              No check-in history at this time
            </h1>
          )}
        </>
      )}
    </Layout>
  );
};

export default Page;
