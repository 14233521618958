import { ReactNode } from "react";
import Illustration from "../../assets/images/Illustration.svg";
import { useNavigate, useLocation } from "react-router";

// Components
import Button from "../../components/Button";

const Page = (props: any) => {
  // Props extractor
  const children: ReactNode = props.children;

  // Hooks
  const navigate = useNavigate(),
    location = useLocation();

  return (
    <div className="md:grid md:grid-cols-5 min-h-screen">
      <main className="hidden md:grid col-span-2 h-full bg-teal-500 dark:bg-teal-700">
        <div className="container mx-auto p-5 flex flex-col h-full">
          {/* Title */}
          <div>
            <h1 className="text-white text-3xl font-bold">LKCNHM</h1>
          </div>

          {/* Illustration */}
          <div className="w-full my-auto">
            <div className="flex justify-center items-center">
              <img
                src={Illustration}
                className="object-cover w-[50%]"
                alt="Museum illustration"
              />
            </div>
            <p className="px-10 mt-10 text-white">
              Lorem ipsum dolor, sit amet consectetur adipisicing elit. Nisi
              ipsam tempora recusandae doloremque minima voluptatum ducimus
              reprehenderit perspiciatis sunt asperiores. Asperiores non
              consectetur vitae aut necessitatibus illo aliquid fugit
              dignissimos.
            </p>
          </div>
        </div>
      </main>
      <aside className="col-span-3 h-full pl-10 flex flex-col">
        {/* Button */}
        <div className="flex justify-end mt-5 mr-5 mb-5">
          <Button
            className="
          text-white bg-gradient-to-br from-green-400 to-blue-600 hover:bg-gradient-to-bl focus:ring-4 focus:outline-none focus:ring-green-200 dark:focus:ring-green-800 font-medium rounded-lg text-sm px-5 py-2.5 text-center"
            onClick={() =>
              location.pathname === "/register"
                ? navigate("/login")
                : navigate("/register")
            }
          >
            {location.pathname === "/register" ? "Login" : "Register"}
          </Button>
          {/* <Button /> */}
        </div>
        <div>{children}</div>
      </aside>
    </div>
  );
};

export default Page;
