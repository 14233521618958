import { forwardRef, ReactNode, useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";

import PageHeader from "../../components/PageHead";
import Navigation from "../../components/Navigation";

const Page = forwardRef((props: any, ref: any) => {
  const children: ReactNode = props.children,
    title: string | ReactNode = props.title || "";

  const navigationRef = useRef<any>(null);

  const [heightOfNavigation, setHeightOfNavigation] = useState<any>("0");

  useEffect(() => {
    if (navigationRef?.current?.clientHeight)
      setHeightOfNavigation(navigationRef?.current?.clientHeight);
  }, [navigationRef]);
  return (
    <div className="max-h-screen overflow-auto" ref={ref}>
      <div className="flex flex-col-reverse md:grid md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5">
        <div className="relative" ref={navigationRef}>
          <Navigation className="shadow-lg md:block" />
        </div>
        <div
          style={{
            height:
              heightOfNavigation < 100
                ? `calc(100vh - ${heightOfNavigation}px)`
                : "auto",
          }}
          className={`max-h-screen overflow-auto  md:h-auto md:flex-1 md:col-span-2 lg:col-span-3 xl:col-span-4`}
        >
          <PageHeader title={title}>{children}</PageHeader>
        </div>
      </div>
    </div>
  );
});

export default Page;
