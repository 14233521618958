import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { userApi } from "../../services/user";
import { setCredentials, setUser } from "../../store/userSlice";

const Redirector = (props: any) => {
  const navigate = useNavigate(),
    dispatch = useDispatch();

  const userData = useSelector((state: any) => state.user);
  const [triggerGetOwnProfile, resultOwnProfile] =
    userApi.endpoints.getOwnProfile.useLazyQuery();

  useEffect(() => {
    if (localStorage.getItem("token"))
      dispatch(setCredentials({ access_token: localStorage.getItem("token") }));

    if (!localStorage.getItem("token")) navigate("/login", { replace: true });
  }, []);

  useEffect(() => {
    if (!userData.user && localStorage.getItem("token"))
      triggerGetOwnProfile("");
    else if (
      (localStorage.getItem("token") || userData.token) &&
      userData.user
    ) {
      userData.user?.role === "ADMIN"
        ? navigate("/admin/dashboard", { replace: true })
        : navigate("/customer/dashboard", { replace: true });
    }
  }, [userData]);

  useEffect(() => {
    if (resultOwnProfile.isSuccess) dispatch(setUser(resultOwnProfile.data));
  }, [resultOwnProfile]);

  return <></>;
};

export default Redirector;
