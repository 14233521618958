import React, { ReactNode, useState } from "react";
import { selectOption } from "./index.d";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import Button from "../Button";

import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";

const Component = React.forwardRef(
  (
    {
      childId = "",
      type,
      name,
      options,
      hideLabel,
      label,
      onChange,
      value = "",
      className = "",
      placeholder,
      defaultPlaceholder,
      required,
      error,
      onBlur,
      min = 0,
      max = 0,
      isFlex = false,
      enableDefaultOption = false,
    }: {
      childId?: string;
      type?: string;
      name?: string;
      options?: selectOption[] | any;
      hideLabel?: boolean;
      label?: string | ReactNode;
      onChange?: any;
      value?: string | number | any;
      className?: string;
      placeholder?: string;
      defaultPlaceholder?: string;
      required?: boolean;
      error?: ReactNode;
      onBlur?: any;
      min?: number;
      max?: number;
      isFlex?: boolean;
      enableDefaultOption?: boolean;
    },
    ref: any
  ) => {
    // State
    const [uniqueId] = useState(`${-new Date()}`);
    const [passwordType, setPasswordType] = useState("password");

    switch (type) {
      case "select":
        return (
          <>
            {!hideLabel ? (
              <label
                htmlFor={`${childId}-select`}
                className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
              >
                {label}
              </label>
            ) : null}
            <select
              ref={ref}
              id={`${childId}-select`}
              name={name}
              onChange={onChange}
              onBlur={onBlur}
              value={
                value ||
                (defaultPlaceholder
                  ? defaultPlaceholder
                  : "--Please choose an option--")
              }
              className={`${
                !error
                  ? "bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  : "bg-red-50 border border-red-500 text-red-900 placeholder-red-700 text-sm rounded-lg focus:ring-red-500 focus:border-red-500 block w-full p-2.5 dark:bg-red-100 dark:border-red-400"
              } ${className}`}
            >
              <option disabled={!enableDefaultOption} value="">
                {defaultPlaceholder
                  ? defaultPlaceholder
                  : "--Please choose an option--"}
              </option>
              {options?.map((el: selectOption, i: number) => (
                <option value={el.value} key={`${childId}-${i}-options`}>
                  {el.label}
                </option>
              ))}
            </select>

            {!!error && (
              <p
                id={`${childId}-${uniqueId}-${name}-error`}
                className="mt-2 text-xs text-red-600 dark:text-red-400"
              >
                {Array.isArray(error)
                  ? error.map((err: string) => <p>{err}</p>)
                  : error}
              </p>
            )}
          </>
        );

      case "radio":
        return (
          <div>
            {!hideLabel ? (
              <label
                htmlFor={childId}
                className={`block mb-2 text-sm font-medium ${
                  error
                    ? "text-red-600 dark:text-red-500"
                    : "text-gray-900 dark:text-white"
                }`}
              >
                {label}
              </label>
            ) : null}
            <div className={isFlex ? "flex gap-10" : "block"}>
              {options?.map?.((option: any, i: number) => (
                <div
                  className={`flex items-center mb-2`}
                  key={`option-${i}-loop`}
                >
                  <input
                    ref={ref}
                    id={`${childId}-${i}`}
                    type="radio"
                    value={option.value}
                    name={name}
                    checked={value === option.value}
                    className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                    onChange={onChange}
                    onBlur={onBlur}
                  />
                  <label
                    htmlFor={`${childId}-${i}`}
                    className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                  >
                    {option.label}
                  </label>
                </div>
              ))}
            </div>

            {!!error && (
              <p
                id={`${childId}-${uniqueId}-${name}-error`}
                className="mt-2 text-xs text-red-600 dark:text-red-400"
              >
                {Array.isArray(error)
                  ? error.map((err: string) => <p>{err}</p>)
                  : error}
              </p>
            )}
          </div>
        );

      case "password":
        return (
          <div className="flex items-center">
            <div className="flex-1">
              <div className="relative">
                <input
                  ref={ref}
                  type={passwordType}
                  id={`${childId}-${uniqueId}-${name}`}
                  className={
                    error
                      ? "block px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-transparent rounded-lg border-1 appearance-none dark:text-white dark:border-red-500 border-red-600 dark:focus:border-red-500 focus:outline-none focus:ring-0 focus:border-red-600 peer"
                      : "block px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-transparent rounded-lg border border-gray-300 appearance-none dark:text-white dark:border-slate-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                  }
                  placeholder=" "
                  required={required}
                  value={value}
                  onChange={onChange}
                  onBlur={onBlur}
                  name={name}
                />
                {!hideLabel && (
                  <label
                    htmlFor={`${childId}-${uniqueId}-${name}`}
                    className={
                      error
                        ? "absolute text-sm text-red-600 dark:text-red-500 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-slate-100 dark:bg-slate-800 px-2 peer-focus:px-2 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 left-1"
                        : "absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-slate-100 dark:bg-slate-800 px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 left-1"
                    }
                  >
                    {label}
                  </label>
                )}
              </div>
              {!!error && (
                <p
                  id={`${childId}-${uniqueId}-${name}-error`}
                  className="mt-2 text-xs text-red-600 dark:text-red-400"
                >
                  {Array.isArray(error)
                    ? error.map((err: string) => <p>{err}</p>)
                    : error}
                </p>
              )}
            </div>
            <div
              onClick={() =>
                setPasswordType(
                  passwordType === "password" ? "text" : "password"
                )
              }
              className="rounded-lg hover:bg-slate-200 dark:hover:bg-slate-900 cursor-pointer p-3"
            >
              <div className="flex content-center items-center dark:text-white">
                {passwordType === "password" ? <FaEye /> : <FaEyeSlash />}
              </div>
            </div>
          </div>
        );

      case "tel":
        return (
          <>
            {!hideLabel ? (
              <label
                htmlFor={`${childId}-${uniqueId}-${name}`}
                className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
              >
                {label}
              </label>
            ) : null}
            <PhoneInput
              type={type}
              defaultCountry="SG"
              id={`${childId}-${uniqueId}-${name}`}
              ref={ref}
              className={
                !error
                  ? `flex bg-transparent border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500  w-full p-2.5 dark:border-slate-600 dark:placeholder-gray-400 dark:focus:ring-blue-500 dark:focus:border-blue-500 ${className}`
                  : `flex px-2.5 pb-2.5 pt-2.5 w-full text-sm text-gray-900 bg-transaparent rounded-lg border appearance-none dark:border-red-500 border-red-600 focus:outline-none focus:ring-0 ${className}`
              }
              placeholder={placeholder}
              required={required}
              value={value}
              onChange={(e) => onChange({ target: { value: e } })}
              onBlur={onBlur}
              name={name}
            />

            {!!error && (
              <p
                id={`${childId}-${uniqueId}-${name}-error`}
                className="mt-2 text-xs text-red-600 dark:text-red-400"
              >
                {Array.isArray(error)
                  ? error.map((err: string) => <p>{err}</p>)
                  : error}
              </p>
            )}
          </>
        );
      case "number":
        return (
          <>
            <div className="flex justify-between gap-3">
              <Button
                onClick={() =>
                  onChange({
                    target: {
                      value:
                        (Number(value) || 0) - 1 > min
                          ? (Number(value) || 0) - 1
                          : min,
                    },
                  })
                }
              >
                -
              </Button>
              <div className="relative flex-1">
                <input
                  ref={ref}
                  type="number"
                  id={`${childId}-${uniqueId}-${name}`}
                  className={`pointer-events-none
                  ${
                    error
                      ? "block px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-transparent rounded-lg border-1 appearance-none dark:text-white dark:border-red-500 border-red-600 dark:focus:border-red-500 focus:outline-none focus:ring-0 focus:border-red-600 peer"
                      : "block px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-transparent rounded-lg border border-gray-300 appearance-none dark:text-white dark:border-slate-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                  }`}
                  placeholder=" "
                  required={required}
                  value={value}
                  onChange={onChange}
                  onBlur={onBlur}
                  name={name}
                  disabled
                />
                {!hideLabel && (
                  <label
                    htmlFor={`${childId}-${uniqueId}-${name}`}
                    className={`pointer-events-none ${
                      error
                        ? "absolute text-sm text-red-600 dark:text-red-500 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-slate-100 dark:bg-slate-800 px-2 peer-focus:px-2 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 left-1"
                        : "absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-slate-100 dark:bg-slate-800 px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 left-1"
                    }`}
                  >
                    {label}
                  </label>
                )}
              </div>
              <Button
                onClick={() =>
                  onChange({
                    target: {
                      value:
                        (Number(value) || 0) + 1 < max
                          ? (Number(value) || 0) + 1
                          : max,
                    },
                  })
                }
              >
                +
              </Button>
            </div>
            {!!error && (
              <p
                id={`${childId}-${uniqueId}-${name}-error`}
                className="mt-2 text-xs text-red-600 dark:text-red-400"
              >
                {Array.isArray(error)
                  ? error.map((err: string) => <p>{err}</p>)
                  : error}
              </p>
            )}
          </>
        );
      default:
        return (
          <>
            <div className="relative">
              <input
                ref={ref}
                type="text"
                id={`${childId}-${uniqueId}-${name}`}
                className={
                  error
                    ? "block px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-transparent rounded-lg border-1 appearance-none dark:text-white dark:border-red-500 border-red-600 dark:focus:border-red-500 focus:outline-none focus:ring-0 focus:border-red-600 peer"
                    : "block px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-transparent rounded-lg border border-gray-300 appearance-none dark:text-white dark:border-slate-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                }
                placeholder=" "
                required={required}
                value={value}
                onChange={onChange}
                onBlur={onBlur}
                name={name}
              />
              {!hideLabel && (
                <label
                  htmlFor={`${childId}-${uniqueId}-${name}`}
                  className={
                    error
                      ? "absolute text-sm text-red-600 dark:text-red-500 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-slate-100 dark:bg-slate-800 px-2 peer-focus:px-2 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 left-1"
                      : "absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-slate-100 dark:bg-slate-800 px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 left-1"
                  }
                >
                  {label}
                </label>
              )}
            </div>
            {!!error && (
              <p
                id={`${childId}-${uniqueId}-${name}-error`}
                className="mt-2 text-xs text-red-600 dark:text-red-400"
              >
                {Array.isArray(error)
                  ? error.map((err: string) => <p>{err}</p>)
                  : error}
              </p>
            )}
          </>
        );
    }
  }
);

export default Component;
